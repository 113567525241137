import MeService from '../../services/me';
import { isEmptyOrNull, isObject, isString } from '../../helpers';
import { SET_AUTH_ERROR } from '../auth/authActions';
import { ME_SET_ALL } from '../me/meActions';
import { ME_SET_PARENT } from '../me/meActions';
import { WIDGET_SET_ALL } from '../widget/widgetActions';
import { NOTIFICATION_SET_ALL } from '../notification/notificationActions';
import { REGION_SET_ALL } from '../region/regionActions';
import { SERVER_SET_ALL } from '../server/serverActions';
import { WEBSITE_SET_ALL } from '../website/websiteActions';
import { SETTING_SET_ALL } from '../setting/settingActions';
import { GLOBAL_PACKAGE_SET_ALL } from '../globalPackage/globalPackageActions';
import { TOOL_SET_ALL } from '../tool/toolActions';
import { USER_SET_ALL } from '../user/userActions';
import { CUSTOMER_SET_ALL } from '../customer/customerActions';
import { PARTNER_PACKAGE_SET_ALL } from '../partnerPackage/partnerPackageActions';
import { BILLING_SET_ALL } from '../billing/billingActions';
import { CUSTOMER_BILLING_SET_ALL } from '../customerBilling/customerBillingActions';
import { DNS_ZONE_SET_ALL } from '../dnsZone/dnsZoneActions';
import { PLATFORM_OPTIONS } from '../platform/platformActions';
import { dispatchPostPromise } from 'helpers';
import StringHelper from 'helpers/string';
import env from 'config/env';

export const SET_GLOBAL_LOADER = 'SET_GLOBAL_LOADER';
export const SET_INPUT_RULES = 'SET_INPUT_RULES';
export const SET_VIEW_PREFERENCES = 'SET_VIEW_PREFERENCES';
export const MESSAGE_SET = 'MESSAGE_SET';
export const MESSAGE_REMOVE = 'MESSAGE_REMOVE';
export const SET_SECONDARY_SIDERBAR = 'SET_SECONDARY_SIDERBAR';
export const NOTIFICATION_CREATE = 'NOTIFICATION_CREATE';

/*
  Action Creators
*/

// Global application loader
export const setGlobalLoader = state => {
  return dispatch => {
    dispatch({ type: SET_GLOBAL_LOADER, payload: state });
  };
};

// Load startup data into each state object
const loadStartupData = (res, dispatch) => {
  if (res.me) {
    dispatch({ type: ME_SET_ALL, payload: res.me });
  }
  if (res.parent) {
    dispatch({ type: ME_SET_PARENT, payload: res.parent });
  }
  if (res.widgets) {
    dispatch({ type: WIDGET_SET_ALL, payload: res.widgets });
  }
  if (res.notifications) {
    dispatch({ type: NOTIFICATION_SET_ALL, payload: res.notifications });
  }
  if (res.regions) {
    dispatch({ type: REGION_SET_ALL, payload: res.regions });
  }
  if (res.servers) {
    dispatch({ type: SERVER_SET_ALL, payload: res.servers });
  }
  if (res.websites) {
    dispatch({ type: WEBSITE_SET_ALL, payload: res.websites });
  }
  if (res.global_settings) {
    dispatch({ type: SETTING_SET_ALL, payload: res.global_settings });
  }
  if (res.global_packages) {
    dispatch({ type: GLOBAL_PACKAGE_SET_ALL, payload: res.global_packages });
  }
  if (res.tools) {
    dispatch({ type: TOOL_SET_ALL, payload: res.tools });
  }
  if (res.partner_packages) {
    dispatch({ type: PARTNER_PACKAGE_SET_ALL, payload: res.partner_packages });
  }
  if (res.customers) {
    dispatch({ type: CUSTOMER_SET_ALL, payload: res.customers });
  }
  if (res.users) {
    dispatch({ type: USER_SET_ALL, payload: res.users });
  }
  if (res.billing) {
    dispatch({ type: BILLING_SET_ALL, payload: res.billing });
  }
  if (res.customers_billing) {
    dispatch({ type: CUSTOMER_BILLING_SET_ALL, payload: res.customers_billing });
  }
  if (res.dns_zones) {
    dispatch({ type: DNS_ZONE_SET_ALL, payload: res.dns_zones });
  }
  if (res.platform_options) {
    dispatch({ type: PLATFORM_OPTIONS, payload: res.platform_options });
  }
  if (res.input_rules) {
    dispatch({ type: SET_INPUT_RULES, payload: res.input_rules });
  }
  if (res.view_preferences) {
    dispatch({ type: SET_VIEW_PREFERENCES, payload: res.view_preferences });
  }
  dispatch({ type: SET_GLOBAL_LOADER, payload: false });
}

// Get global data for logged in user
export const getGlobalData = () => {
  return dispatch => {
    if (env.offlineMode) {
      // Read data from local json file.
      const res = require('../../data/demo.json');
      console.log('res', res);
      loadStartupData(res, dispatch);
    } else {
      // Get real data from the API.
      MeService.me()
        .then(res => loadStartupData(res, dispatch))
        .catch(err => {
          dispatch({ type: SET_AUTH_ERROR, payload: err });
          dispatch({ type: SET_GLOBAL_LOADER, payload: false });
        });
    }
  };
};

// Update my view preferences
export const updateMyViewPreferences = (data) => {
  return dispatchPostPromise(MeService.updateViewPreferences(data), SET_VIEW_PREFERENCES);
};

// Set a global message
export const setGlobalMsg = body => {
  return dispatch => {
    body.text = StringHelper.removeWhitespaces(body.text);
    body.text = StringHelper.capitalizeFirstLetter(body.text);
    const notification = {
      time: new Date(),
      text: body.text,
      color: body.color,
      slug: StringHelper.randomString(),
    };
    dispatch({ type: NOTIFICATION_CREATE, payload: notification });
    dispatch({ type: MESSAGE_SET, payload: body });
  };
};

// Set a global please wait message
export const setGlobalPleaseWaitMsg = body => {
  if (isString(body)) {
    body = { text: body };
  } else {
    body.action = StringHelper.lowercaseFirstLetter(body.action || 'updated');
    body.id = _boldifyText(body.id);
    if (body.onId) {
      body.id += ' on ' + _boldifyText(body.onId);
    } else if (body.forId) {
      body.id += ' for ' + _boldifyText(body.forId);
    }
    body.extra = _convertExtraToText(body);
    body.conj = body.conj || (body.plural ? 'are' : 'is');
    body.text = `${body.model} ${body.id} ${body.conj} being ${body.action}${body.extra}. Please wait...`;
  }
  return setGlobalInfoMsg(body);
};

// Set a global success message
export const setGlobalSuccessMsg = body => {
  if (isString(body)) {
    body = { text: body };
  } else {
    body.id = _boldifyText(body.id);
    if (body.onId) {
      body.id += ' on ' + _boldifyText(body.onId);
    } else if (body.forId) {
      body.id += ' for ' + _boldifyText(body.forId);
    } else if (body.fromId) {
      body.id += ' from ' + _boldifyText(body.fromId);
    }
    body.extra = _convertExtraToText(body);
    body.action = StringHelper.lowercaseFirstLetter(body.action || 'saved');
    body.conj = body.conj || (body.plural ? 'have' : 'has');
    body.text = `${body.model.replace(/-/g, ' ')} ${body.id} ${body.conj} been successfully ${body.action}${body.extra}.`;
  }
  body.color = 'success';
  return setGlobalMsg(body);
};

// Set a global error message
export const setGlobalErrorMsg = body => {
  const text = getErrorText(body);
  if (text !== body) {
    window.logHelper.error(text, body);
  } else {
    window.logHelper.error(text);
  }
  return setGlobalMsg({ text, color: 'danger' });
};

// Set a global warning message
export const setGlobalWarningMsg = body => {
  if (isString(body)) {
    body = { text: body };
  }
  body.text = body.text || `Caution, but nothing to fear about :)`;
  body.color = 'warning';
  window.logHelper.warning(body.text, body);
  return setGlobalMsg(body);
};

// Set a global info message
export const setGlobalInfoMsg = body => {
  if (isString(body)) {
    body = { text: body };
  }
  body.color = 'info';
  return setGlobalMsg(body);
};

// Set a global resource is busy message
export const setResourceIsBusyMsg = body => {
  body.id = _boldifyText(body.id);
  body.text = `Please wait until ${body.id} ${body.model} becomes ready.`;
  return setGlobalWarningMsg(body);
};

// Set secondary sidebar state (show/hide)
export const setSecondarySidebar = state => {
  return dispatch => dispatch({ type: SET_SECONDARY_SIDERBAR, payload: state });
};

function _prettifyServerError(text) {
  if (isEmptyOrNull(text) || !isString(text)) {
    return 'Oops! Something went wrong.';
  } else if (text.includes('has been a critical error')) {
    return 'Oops! Something went wrong with the WordPress site.';
  } else if (text.includes('SQLSTATE')) {
    return 'Oops! Something internal went wrong.';
  } else if (!text.includes('cron command') && ['cURL', 'wget', 'file_get_contents'].some(str => text.toLowerCase().includes(str))) {
    return 'Oops! Something went wrong with the server connection.';
  } else if (['target class', 'undefined '].some(str => text.toLowerCase().includes(str))) {
    return 'Oops! Something went wrong with the server response.';
  } else if (text.includes('Operation timed out after')) {
    return 'Operation timed out. Please try again.';
  } else if (text.includes('Job failed with error: ')) {
    return text.replace('Job failed with error: ', '');
  } else if (text.length > 150) {
    text = text.substring(0, 150) + '...';
  }
  return text;
}

// Guesses error msg from error object
export const getErrorText = body => {
  let text = null;
  if (isObject(body) && body.data && body.data.error) {
    text = body.data.error;
  } else if (isString(body)) {
    text = body;
  }
  if (!text) {
    window.logHelper.error('Failed to get error message from object:', body);
  }
  return _prettifyServerError(text);
}

function _convertExtraToText(body) {
  if (body.extra) {
    return body.extra;
  } else if (body.from) {
    return ' from ' + _boldifyText(body.from);
  } else if (body.to) {
    return ' to ' + _boldifyText(body.to);
  }
  return '';
}

function _boldifyText(text) {
  if (!text) {
    return '';
  }
  return `<span style='font-weight: bold'>${text}</span>`
}
