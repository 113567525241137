// API Services
import UserService from 'services/user';
import BillingService from 'services/billing';
// Helpers
import { dispatchGetPromise, dispatchPostPromise, dispatchDeletePromise } from 'helpers';

/*
  Action Types
*/
export const USER_SET_ALL = 'USER_SET_ALL';
export const USER_CREATE = 'USER_CREATE';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_DELETE = 'USER_DELETE';

/*
  Action Creators
*/

// Get all users
export const getAllUsers = data => {
  return dispatchGetPromise(UserService.list(data), USER_SET_ALL);
};

// Create new user
export const createUser = data => {
  return dispatchPostPromise(UserService.create(data), USER_CREATE);
};

// Update specified user details
export const updateUser = data => {
  return dispatchPostPromise(UserService.update(data), USER_UPDATE);
};

// Update specified user details
export const updateUserTwoFactorStatus = data => {
  return dispatchPostPromise(UserService.updateTwoFactorStatus(data), USER_UPDATE);
};

// Delete specified user
export const deleteUser = employee => {
  const data = { user_slug: employee.slug };
  return dispatchDeletePromise(UserService.delete(data), USER_DELETE, employee);
};

export const setPartnerCustomWPAdminLoginUser = data => {
  return dispatchPostPromise(UserService.updateCustomWPAdminLoginUser(data), USER_UPDATE);
};

export const updatePartnerBillingCredits = data => {
  return dispatchPostPromise(BillingService.updatePartnerCredits(data), USER_UPDATE);
};

// Suspend user account
export const suspendPartnerAccount = data => {
  return dispatchPostPromise(UserService.suspend(data), USER_UPDATE);
};

// Unsuspend user account
export const unsuspendPartnerAccount = data => {
  return dispatchPostPromise(UserService.unsuspend(data), USER_UPDATE);
};