import React, { Fragment } from 'react';
import { WPSCardContainer, WPSPlanCard } from '../../styles/layout/cards';
import { WPSButton } from '../../styles/layout/buttons';
import { IoMdCheckmark } from 'react-icons/io';
import { isEmpty } from '../../helpers';
import PlanHelper from '../../helpers/plan';
import ObjectHelper from 'helpers/object';
import { useSelector } from 'react-redux';
import { subscriptionsSelector } from '../../store/billing/billingSelectors';
import Tooltip from 'components/layout/tooltip';
import ReactTooltip from 'react-tooltip';

const PricingPlans = ({ plans, selectedSlug, customCard, name, type, handleOnClick }) => {
  const allSubscriptions = useSelector(subscriptionsSelector);

  const handleBtnClick = (e, plan) => {
    e.preventDefault();
    handleOnClick({
      target: { name: name, value: plan.slug },
    });
  };

  const isSelected = plan => {
    return selectedSlug && selectedSlug === plan.slug;
  };

  const renderFeature = (f) => 
    <div key={f.name}>
      <div className='item'>
        <strong><IoMdCheckmark />{PlanHelper.featureNameToText(f.name)}:</strong>
        <span>{PlanHelper.featureValueToText(f)}</span>
      </div>
      {f.allow_extra_units && <div key={f.name + '-extra'} className='item-extra'>
        <span>+${f.extra_unit_price} / {f.unit} for excess usage</span>
      </div>}
    </div>

  const renderFeatureWithTooltip = (f, label, value) =>
    <div key={f.name}>
      <div className='item'>
        <strong><IoMdCheckmark />{label}:</strong>
        <span className='tooltip-relative-parent' style={{ height: '17px' }}>
          <span>{value}</span><Tooltip place='right' text={f.description}/>
        </span>
      </div>
      {f.allow_extra_units && <div key={f.name + '-extra'} className='item-extra'>
        <span>+${f.extra_unit_price} / {f.unit} for excess usage</span>
      </div>}
    </div>

  return (
    <WPSCardContainer>
      {plans.map(plan => {
        const subscriptions = allSubscriptions.filter(
          subscription => subscription.plan_slug === plan.slug && subscription.is_active,
        );
        let isActive = false;
        !isEmpty(subscriptions) &&
          subscriptions.forEach(subscription => {
            if (subscription.is_active) {
              isActive = true;
            }
          });

        const allowMultiSubs = plan.allow_multi_subs;
        const features = plan.features;
        return (
          <WPSPlanCard style={{ position: 'relative' }} key={plan.slug}>
            <div className={customCard ? (isSelected(plan) ? 'title' : 'title gray-bg') : 'title'}>
              <h3>{plan.name}</h3>
              <div className='price'>
                <span className='number'>${plan.price}</span>
                <span>/{plan.interval}</span>
              </div>
            </div>
            {type === 'website' && (
              <div className={customCard ? 'body margin-bottom-70' : 'body'}>
                {features.map(f => { 
                  if (f.name.toLowerCase().includes("trial days")) {
                    return renderFeatureWithTooltip(f, 'Free Trial', `${f.value} days`)
                  }
                  if (f.name.toLowerCase().includes("php memory")) {
                    return renderFeatureWithTooltip(f, 'PHP memory', PlanHelper.featureValueToText(f))
                  }
                  return renderFeature(f)
                })}
                {allowMultiSubs && !customCard ? (
                  <Fragment>
                    {!isEmpty(subscriptions) && (
                      <Fragment>
                        <WPSButton disabled={true}>
                          <div
                            data-for={`btn-tooltip-${plan.slug}`}
                            data-tip={`Subscription is automatic once you create a ${plan.category_slug.split('_')[0]}.`}
                            data-tip-disable={false}>
                            {`${subscriptions.length} Active`}
                          </div>
                        </WPSButton>
                        <ReactTooltip class='btn-disabled-tooltip' id={`btn-tooltip-${plan.slug}`} place='right'/>
                      </Fragment>
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    {!customCard && (
                      <WPSButton
                        disabled={isActive}
                        className={isActive ? 'success--btn' : 'info--btn'}
                        onClick={() => handleOnClick(plan)}>
                        {isActive ? 'Activated' : 'Subscribe'}
                      </WPSButton>
                    )}
                  </Fragment>
                )}
                {customCard && (
                  <Fragment>
                    <WPSButton
                      className={isSelected(plan) ? 'card-selected--btn' : 'card-option--btn'}
                      style={{
                        position: 'absolute',
                        bottom: '15px',
                        left: '50%',
                        marginLeft: '-65px'
                      }}
                      disabled={ObjectHelper.hasKey(plan, 'select_disable') && plan.select_disable}
                      onClick={e => handleBtnClick(e, plan)}>
                        <div data-for={`btn-tooltip-${plan.slug}`} data-tip={plan.select_disable_reason} data-tip-disable={false}>
                          {isSelected(plan) ? 'Selected' : 'Select'}
                        </div>
                    </WPSButton>
                    <ReactTooltip class='btn-disabled-tooltip' id={`btn-tooltip-${plan.slug}`} place='right'/>
                  </Fragment>
                )}
              </div>
            )}
            {type === 'domain' && (
              <div className='body'>
                <div className='item'>
                  <strong>
                    <IoMdCheckmark /> TLDs:
                  </strong>
                  <span>
                    <ul>
                      {plan.features[0].value.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  </span>
                </div>
                <div className='item'>
                  <strong>
                    <IoMdCheckmark /> Domains included:
                  </strong>
                  <span>{plan.features[1].value}</span>
                </div>
                <WPSButton
                  className={isActive ? 'success--btn' : 'info--btn'}
                  onClick={() => handleOnClick(plan)}>
                  {isActive ? 'Activated' : 'Subscribe'}
                </WPSButton>
              </div>
            )}
            {type === 'email' && (
              <div className='body'>
                <div className='item'>
                  <strong>
                    <IoMdCheckmark /> Mailbox side:
                  </strong>
                  <span>
                    {plan.features[0].value} {plan.features[0].unit}
                  </span>
                </div>
                <div className='item'>
                  <strong>
                    <IoMdCheckmark /> Send limit:
                  </strong>
                  <span>{plan.features[1].value}/day</span>
                </div>
                <div className='item'>
                  <strong>
                    <IoMdCheckmark /> Domains included:
                  </strong>
                  <span>{plan.features[3].value}</span>
                </div>
                <div className='item'>
                  <strong>
                    <IoMdCheckmark /> Users included:
                  </strong>
                  <span>{plan.features[3].value}/domain</span>
                </div>
                <WPSButton
                  className={isActive ? 'success--btn' : 'info--btn'}
                  onClick={() => handleOnClick(plan)}>
                  {isActive ? 'Activated' : 'Subscribe'}
                </WPSButton>
              </div>
            )}
          </WPSPlanCard>
        );
      })}
    </WPSCardContainer>
  );
};

export default PricingPlans;
